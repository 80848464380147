<template>
    <div class="page home retrievedResult">
        <div class="mainColor flex_l_c pointer backBtn" @click="back"><span class="el-icon-arrow-left"></span>返回</div>
        <div class="plateBox tabBox">
            <div class="flex_l_c">
                <div :class="['tabItem flex_c_c', tabIndex == index ? 'mainBdColor mainColor': 'resetColor resetBdColor']"
                     v-for="(item, index) in tabArr" :key="index" @click="tabChange(index)">
                    <span :class="['iconfont', item.icon, tabIndex == index ? 'mainColor': 'resetIconColor']"></span>
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <!--展览-->
        <Exhibition ref="exhibition" v-show="tabIndex == 0" :homeSrh="homeSrh" @changeSrh="changeSrh"></Exhibition>
        <!--档案-->
        <Record ref="record" v-show="tabIndex == 1" :homeSrh="homeSrh" @changeSrh="changeSrh"></Record>
        <!--文物-->
        <Cultural ref="cultural" v-show="tabIndex == 2" :homeSrh="homeSrh" @changeSrh="changeSrh"></Cultural>
        <!--全文-->
        <Fulltext ref="fulltext" v-show="tabIndex == 3" :homeSrh="homeSrh" @changeSrh="changeSrh"></Fulltext>
        <!--团队-->
        <Team ref="team" v-show="tabIndex == 4" :homeSrh="homeSrh" @changeSrh="changeSrh"></Team>
    </div>
</template>

<script>
    import Exhibition from './exhibition'
    import Record from './record'
    import Cultural from './cultural'
    import Fulltext from './fulltext'
    import Team from './team'

    export default {
        components: {Exhibition, Record, Cultural, Fulltext, Team},
        data() {
            return {
                homeSrh: '',
                tabIndex: 0,
                tabArr: [
                    {
                        name: '展览',
                        icon: 'icon-zhanlan'
                    },
                    {
                        name: '档案',
                        icon: 'icon-dangan'
                    },
                    {
                        name: '文物',
                        icon: 'icon-wenwu-mian'
                    },
                    {
                        name: '全文',
                        icon: 'icon-quanwenjiansuo'
                    },
                    {
                        name: '展览团队成员',
                        icon: 'icon-chengyuan'
                    }
                ],
                permissionsFlag : false,
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
            },
        },
        created() {
            if(sessionStorage.getItem('homeSrh')) {
                this.homeSrh = sessionStorage.getItem('homeSrh')
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
            let dictList = JSON.parse(sessionStorage.getItem('allMenuList') || '[]')
            dictList.forEach(v=>{
                if(v.name=="展览团队成员"){
                    this.permissionsFlag =true;
                }
            })
            if(this.$route.query.tabIndex) {
                this.tabIndex = parseInt(this.$route.query.tabIndex)
            }
            if(this.$route.query.val.id!=null && this.$route.query.val.id!=''){
                this.tabIndex = 1;
            }

        },
        methods: {
            changeSrh(val) {
                this.homeSrh = val
                sessionStorage.setItem('homeSrh', val)
            },
            // 初始化
            init() {
                this.tabIndex = 0;
            },
            // tab切换
            tabChange(index) {
                this.tabIndex = index;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
            },
            // 高级查询
            advancedQuery() {
                this.$refs.advancedQuery.init(this.config)
            },
            // 返回
            back() {
                this.$router.push({path: '/zlzs/exhibitionHome'});
            },
        }
    }
</script>

<style scoped>

</style>
