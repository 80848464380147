<template>
  <!--全文-->
  <div>
    <div class="plateBox srhBox">
      <div class="flex_l_c">
        <input type="text" class="mainBdColor srhIpt" placeholder="请输入搜索关键词" v-model="srhStr">
        <button class="srhBtn mainBdColor mainBgColor m_r1" @click="search"><i class="el-icon-search"></i>搜索</button>
        <el-button type="text" @click="labelIndexQueryAll2">更多选项</el-button>
      </div>
      <div class="more0ptions" v-if="more0ptions">
        <el-checkbox class="m_b1" :indeterminate="isIndeterminate" v-model="checkAll"
          @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cityOptions" :label="city.id" :key="city.id">{{ city.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="plateBox">
      <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选结果/操作</span></div>
      <div class="m_t1" v-loading="loading">
        <div class="itemBox" v-if="list.length > 0">
          <div v-for="(item, index) in list" :key="index" class="item">
            <div class="flex_l_c">
              <img src="../../../../assets/img/word.png" class="wordIcon" v-if="item.docType == 'doc'">
              <img src="../../../../assets/img/pdf.png" class="wordIcon" v-if="item.docType == 'pdf'">
              <div class="omit flex1" v-html="item.docTitle"></div>
            </div>
            <div class="content" v-html="item.docContent"></div>
            <el-row :gutter="20">
              <el-col :span="18">
                <div class="m_t1">
                  <el-tag size="mini" v-for="(itemC, indexC) in item.docTitleHighLightList" :key="indexC">{{ itemC }}
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="6">
                <el-button type="text" @click="view(item.docFileId)">详情</el-button>
                <el-button type="text" @click="download(item, 1)">下载</el-button>
                <el-button type="text" @click="collect(item.docFileId, 1)" v-if="!item.collectionId">收藏</el-button>
                <el-button type="text" v-else @click="cancelCollect(item.collectionId)">取消收藏</el-button>
                <el-button type="text" @click="borrow(item, 1)">借阅</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-empty description="暂无数据" v-else></el-empty>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
    <!-- <BorrowForm ref="borrowForm"></BorrowForm> -->
    <!--查看-->
    <DetailPage ref="detailPage" @refreshDataList="search"></DetailPage>
    <!--借阅-->
    <BorrowForm ref="borrowForm"></BorrowForm>
  </div>
</template>

<script>
import BorrowForm from '../search/BorrowForm' // 借阅弹窗
import DetailPage from '../search/detailPage' // 查看弹窗
export default {
  components: { BorrowForm, DetailPage },
  props: ['homeSrh'],
  data() {
    return {
      srhStr: '',
      more0ptions: false,
      checkAll: false,
      checkedCities: [],
      cityOptions: [],
      isIndeterminate: false,
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false
    }
  },
  computed: {
    // 主题色
    defaultTheme() {
      return this.$store.state.config.defaultTheme
    },
  },
  watch: {
    defaultTheme() {
      this.changeMainColor(this.defaultTheme);
    },
    homeSrh(val) {
      this.srhStr = val;
      this.search();
    },
    srhStr(val) {
      this.$emit('changeSrh', val)
    },
  },
  created() {
    this.srhStr = this.homeSrh;
  },
  mounted() {
    this.$nextTick(() => {
      this.changeMainColor(this.defaultTheme);
    })
    this.search();
  },
  methods: {
    search2() {
      this.pageNo = 1
      this.pageSize = 10
      this.search();
    },
    labelIndexQueryAll2() {
      this.more0ptions = !this.more0ptions
      if (this.more0ptions) {
        this.$axios(this.api.common.labelIndexQueryAll2, {}, 'get').then(data => {
          if (data && data.status) {
            this.cityOptions = data.data
          }
        })
      }
    },
    // 收藏
    collect(id, val) {
      let ids = val == 1 ? id : this.dataListSelections.map(item => {
        return item.id
      }).join(',')
      this.$axios(this.api.common.MyCollectionSave, {
        fileIds: ids
      }, 'post').then(data => {
        if (data.status) {
          this.$message.success("收藏成功")
        } else {
          this.$message.error("收藏失败")
        }
        this.search();
      })
    },
    // 取消收藏
    cancelCollect(collectionId) {
      this.$axios(this.api.common.MyCollectionRemoveById, {
        ids: collectionId
      }, 'delete').then(data => {
        if (data.status) {
          this.$message.success("取消收藏成功")
        } else {
          this.$message.error("取消收藏失败")
        }
        this.search();
      })
    },
    // 借阅
    borrow(item, val) {
      let api = this.api.common.queryAllByLimitRetrieve
      this.$axios(api, {
        'fileId': item.docFileId,
        'pageNo': 1,
        'pageSize': 1,
        "name": '',
        "exhibitionId": '',
        "directoryId": '',
        "labelId": '',
        'flag': 4,
        'onDetails': 1,
      }, 'get').then(data => {
        console.log("===========", data)
        if (data && data.status) {
          this.$refs.borrowForm.init(data.data.records)
        } else {
          this.$message.error("系统异常")
        }
      })
    },
    // 下载
    download(row, val) {
      let api = this.api.common.queryByIdRetrieve
      this.$axios(api, {
        'id': row.docFileId,
        'flag': 2,
        'onDetails': 0
      }, 'get').then(data => {
        console.log("===========", data)
        if (data.status) {
          let log = {
            operModular: "5",//模块
            operType: "19",//类型
            operTerm: data.fileName,//内容
            operSystem: "1",//结果:失败
          }
          const a = document.createElement('a')
          //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
          const url = data.data.url;  // 完整的url则直接使用
          // 这里是将url转成blob地址，
          fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
            a.href = URL.createObjectURL(blob)
            // console.log(a.href)
            a.download = data.data.fileName + "." + data.data.fileFormat // 下载文件的名字
            // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
            document.body.appendChild(a)
            a.click()
            log.operSystem = "0"
            // this.$axios(this.api.common.logSave, log, 'post')
          })
        } else {
          this.$message.error("您暂无下载权限，请提交借阅申请")
        }
      })
    },
    // 全选
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cityOptions.map(item => {
        return item.id
      }) : [];
      this.isIndeterminate = false;
    },
    // 搜索
    search() {
      let params = {};
      let paramsJson = {};
      // console.log(this.checkedCities);
      // this.srhStr.replace(/\s/g,"")
      if (this.srhStr.replace(/\s/g, "") === '') {// && this.checkedCities.length==0
        this.list = []
        this.pageNo = 1
        this.total = 0
        return;
      }
      this.loading = true
      paramsJson.words = this.srhStr;//查询字符串
      paramsJson.wordsLocation = 0;//文件位置
      paramsJson.docSizeLevel = 0;//文件大小
      paramsJson.docType = "all";//文件类型
      paramsJson.labelId = this.checkedCities.map(item => {
        return item
      }).join(',');//文件类型
      params.paramsJson = JSON.stringify(paramsJson);
      params.page = this.pageNo;
      params.size = this.pageSize;
      console.log(this.api.docsearch.searchDoc);
      this.$axios("zyd-catalogue/doc/searchDoc", params, 'get').then(data => {
        if (data && data.status) {
          this.list = data.data.records
          this.loading = false
          this.total = parseInt(data.data.total)
        }

      })
    },
    view(row) {
      this.$refs.detailPage.init(row, 'itemRetrieval')
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.search()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.search()
    },
  },
}
</script>

<style scoped>
.itemBox {
  height: calc(100vh - 240px);
  overflow-y: auto;
  margin: 15px 0 0;
  padding: 1px;
}

.item {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  margin-bottom: 15px;
  padding: 15px;
  color: #333333;
}

.wordIcon {
  width: 24px;
  margin-right: 10px;
}

.content {
  font-size: 13px;
  margin-top: 10px;
}
</style>
